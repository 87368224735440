import React, { useEffect } from "react";

import FiftyFiftyBanner from "@vfuk/core-fifty-fifty-banner";
import MainContent from "../components/MainContent";
import SimpleGrid from "@vfuk/core-simple-grid";
import Heading from "@vfuk/core-heading";

import Card from "../components/Card";
import Support from "../components/Support";
import Footer from "../components/Footer";
import flagGermany from "../img/germany.png";
import flagUK from "../img/UK.svg";
import flagIreland from '../img/ireland.svg';
import headerImageMobile from "../img/header/Mobile.jpg";
import headerImageTablet from "../img/header/Tablet.jpg";
import headerImageDesktop from "../img/header/Desktop.jpg";
import headerImageXLDesktop from "../img/header/Desktop@2x.jpg";
import Layout from "../components/Layout";

const IndexPage = () => {
  useEffect(() => {
    window.location.replace("https://support.vodafonebusiness.ringcentral.com/gb/en/contact-us.html");
  }, []);

  return (
    <Layout title="Support - Vodafone Business UC with RingCentral">
      <FiftyFiftyBanner
        appearance="primary"
        heading={{ text: "Get support" }}
        text="Support for your Vodafone Business UC with RingCentral solution"
        backgroundImage={{
          sm: {
            src: headerImageMobile,
          },
          md: {
            src: headerImageTablet,
          },
          lg: {
            src: headerImageDesktop,
          },
          xl: {
            src: headerImageXLDesktop,
          },
        }}
      />

      <MainContent>
        <br />
        <br />
        <Heading weight={3} size={2}>
          Select your market
        </Heading>
        <br />
        <Card>
          <SimpleGrid
            columns={{ sm: 1, md: 2, lg: 4 }}
            spacing={8}
            justify="center"
          >
            <Support
              icon={flagUK}
              title="United Kingdom"
              linkUrl="https://www.vodafone.co.uk/business/webchat-fixed"
            />
            <Support
              icon={flagGermany}
              title="Germany"
              linkUrl="https://www.vodafone.de/business/hilfe-support/ringcentral.html"
            />
            <Support
              icon={flagIreland}
              title="Ireland"
              linkUrl="https://n.vodafone.ie/business/support.html"
            />
            <Support
              vfIcon="international"
              title="Multi National"
              linkText="Open a web case"
              linkUrl="https://portal.vodafone.com/"
            />
          </SimpleGrid>
        </Card>
      </MainContent>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
