import React from "react";
import LinkWithIcon from "@vfuk/core-link-with-icon";
import Heading from "@vfuk/core-heading";
import Icon from "@vfuk/core-icon";

interface SupportProps {
  icon?: any;
  vfIcon?: string;
  title: string;
  linkText?: string;
  linkUrl: string;
}

const Support = (props: SupportProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      {props.icon && (
        <img
          src={props.icon}
          width="26px"
          height="26px"
          alt="United Kingdom"
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      )}

      {props.vfIcon && (
        <div
          style={{
            marginBottom: "10px",
            display: "inline-flex",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Icon size={4} name={props.vfIcon} group="system" />
        </div>
      )}

      <Heading justify="center" size={1} weight={2}>
        {props.title}
      </Heading>
      <div
        className="link-container"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <LinkWithIcon
          appearance="primary"
          state="selected"
          htmlAttributes={{
            target: "_blank",
          }}
          text={props.linkText || "Contact support"}
          icon={{
            name: "chevron-right",
            justify: "right",
          }}
          href={props.linkUrl}
          size={1}
        />
      </div>
    </div>
  );
};

export default Support;
