import React from "react";

const Card = ({ children }) => {
  return (
    <div
      style={{
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "32px",
        paddingBottom: "32px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 8px 0px",
        borderRadius: "6px",
        backgroundColor: "white",
      }}
    >
      {children}
    </div>
  );
};

export default Card;
