import Heading from "@vfuk/core-heading";
import Paragraph from "@vfuk/core-paragraph";
import Link from "@vfuk/core-link";

import React from "react";
import RCLogoWhite from "../img/rc_white.svg";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        width: "100%",
        minWidth: "100%",
      }}
    >
      <div style={{ maxWidth: "1232px", padding: "48px 16px", margin: "auto" }}>
        <Paragraph inverse size={3}>
          Provided by
        </Paragraph>
        <img src={RCLogoWhite} width="150px" alt="RingCentral Logo" />
        <hr style={{ borderColor: "#333333", marginTop: "32px" }} />
        <Link
          inverse
          htmlAttributes={{
            target: "_blank",
          }}
          href="https://www.ringcentral.co.uk/legal/"
          appearance="primary"
          text="Legal"
        />
      </div>
    </div>
  );
};

export default Footer;
